import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  InputSignal,
  Signal,
  computed,
  inject,
  input,
} from '@angular/core';
import { ChartType, Color } from 'chart.js';

import { DataDisplayFn, TooltipConfig } from '../../../models';
import { isDoughnutOrPieChart } from '../../../utils';
import { ChartListItemComponent } from '../chart-list-item/chart-list-item.component';

@Component({
  selector: 'mp-chart-tooltip',
  standalone: true,
  templateUrl: './chart-tooltip.component.html',
  styleUrl: './chart-tooltip.component.scss',
  host: {
    '[style.display]': 'tooltipConfig() ? "" : "none"',
    '[style.left.px]': 'tooltipConfig()?.left ?? 0',
    '[style.top.px]': 'tooltipConfig()?.top ?? 0',
    '[style.opacity]': 'tooltipConfig()?.visible ? 1 : 0',
    '[style.transform]': '"translateX(" + translateXPercent() + "%)"',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ChartListItemComponent],
})
export class ChartTooltipComponent<T extends ChartType> {
  readonly tooltipConfig: InputSignal<TooltipConfig | undefined> = input.required<TooltipConfig | undefined>();

  readonly chartType: InputSignal<T> = input.required<T>();

  readonly dataDisplayFn: InputSignal<DataDisplayFn> = input.required<DataDisplayFn>();

  private readonly elementRef: ElementRef<HTMLDivElement> = inject(ElementRef);

  private readonly DEFAULT_TRANSLATE_X_PERCENT: number = 50;

  protected readonly translateXPercent: Signal<number> = computed((): number => {
    const tooltipConfig: TooltipConfig | undefined = this.tooltipConfig();
    if (!tooltipConfig) {
      return -this.DEFAULT_TRANSLATE_X_PERCENT;
    }

    return this.calculateTooltipTranslateXPercent(tooltipConfig);
  });

  getItemMarkerColor(index: number): Color | undefined {
    if (isDoughnutOrPieChart(this.chartType())) {
      return this.tooltipConfig()?.colors[index].backgroundColor;
    } else {
      return this.tooltipConfig()?.colors[index].borderColor;
    }
  }

  private calculateTooltipTranslateXPercent(tooltipConfig: TooltipConfig): number {
    const tooltipHalfWidth: number = this.elementRef.nativeElement.offsetWidth / 2;
    const { left, leftLimit, rightLimit }: TooltipConfig = tooltipConfig;
    const tooltipLeft: number = left - tooltipHalfWidth;
    const tooltipRight: number = left + tooltipHalfWidth;

    const leftOverflow: number = leftLimit - tooltipLeft;
    const rightOverflow: number = tooltipRight - rightLimit;

    if (leftOverflow > 0) {
      const overflowCoefficient: number = leftOverflow / tooltipHalfWidth;
      return -this.DEFAULT_TRANSLATE_X_PERCENT + overflowCoefficient * this.DEFAULT_TRANSLATE_X_PERCENT;
    } else if (rightOverflow > 0) {
      const overflowCoefficient: number = rightOverflow / tooltipHalfWidth;
      return -this.DEFAULT_TRANSLATE_X_PERCENT - overflowCoefficient * this.DEFAULT_TRANSLATE_X_PERCENT;
    } else {
      return -this.DEFAULT_TRANSLATE_X_PERCENT;
    }
  }
}
