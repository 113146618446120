import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mp-chart-test',
  standalone: true,
  template: `
    <!-- NOTE - Extra defer is needed as in storybook the global styles are loaded just after the DOM tree is created
      which results in wrong colors used to draw chart canvas -->
    @defer (on viewport) {
      <ng-content />
    } @placeholder {
      <span> </span>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTestComponent {}
