<div class="tooltip-title">
  <span class="tooltip-title__line">{{ tooltipConfig()?.title }}</span>
</div>

<ul class="tooltip-values">
  @for (value of tooltipConfig()?.values; track $index) {
    <li class="tooltip-values__value">
      <mp-chart-list-item
        [label]="value.label"
        [value]="value.value"
        [background]="getItemMarkerColor($index)"
        [dataDisplayFn]="dataDisplayFn()"
      />
    </li>
  }
</ul>
