<div class="chart" [style.aspect-ratio]="chartAspectRatio">
  <canvas #canvas role="img"></canvas>

  <mp-chart-tooltip [tooltipConfig]="tooltipConfig()" [chartType]="type()" [dataDisplayFn]="dataDisplayFn()" />

  <ng-content />
</div>

@if (showLegend()) {
  <mp-chart-legend
    class="chart-legend"
    [chartType]="type()"
    [legendItems]="legendItems()"
    [legendValues]="legendValues()"
    [dataDisplayFn]="dataDisplayFn()"
    [legendItemsOrientation]="legendItemsDirection()"
    (legendItemClick)="toggleDataVisibility($event)"
  />
}
