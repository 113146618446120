import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  InputSignal,
  NgZone,
  OnDestroy,
  Signal,
  ViewChild,
  WritableSignal,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { Chart, ChartData, ChartOptions, Legend, LegendItem, Plugin, PluginOptionsByType, Tooltip } from 'chart.js';

import { DataDisplayFn, TooltipConfig } from '../../models';
import { getColorsPlugin, getExternalTooltipFunction, getHtmlLegendPlugin } from '../../plugins';
import { isDoughnutOrPieChart } from '../../utils';

import { applyChartTypeBaseConfig } from './chart-base-config';
import { ChartLegendComponent, LegendItemsOrientation } from './chart-legend/chart-legend.component';
import { ChartTooltipComponent } from './chart-tooltip/chart-tooltip.component';

Chart.register(Legend, Tooltip);

type LegendPosition = 'top' | 'bottom' | 'left' | 'right';

export type ChartAvailableType = 'line' | 'doughnut';

@Component({
  selector: 'mp-chart-base',
  standalone: true,
  templateUrl: './chart-base.component.html',
  styleUrl: './chart-base.component.scss',
  host: {
    'class': 'mp-chart-base',
    '[class.mp-chart-base--legend-top]': 'legendPosition() === "top"',
    '[class.mp-chart-base--legend-bottom]': 'legendPosition() === "bottom"',
    '[class.mp-chart-base--legend-left]': 'legendPosition() === "left"',
    '[class.mp-chart-base--legend-right]': 'legendPosition() === "right"',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ChartLegendComponent, ChartTooltipComponent],
})
export class ChartBaseComponent<T extends ChartAvailableType> implements OnDestroy {
  @ViewChild('canvas') readonly canvas!: ElementRef<HTMLCanvasElement>;

  readonly type: InputSignal<T> = input.required<T>();

  readonly showLegend: InputSignal<boolean> = input<boolean>(true);
  readonly legendPosition: InputSignal<LegendPosition> = input<LegendPosition>('right');
  readonly legendItemsDirection: InputSignal<LegendItemsOrientation> = input<LegendItemsOrientation>('vertical');
  readonly legendValues: InputSignal<number[]> = input<number[]>([]);

  readonly data: InputSignal<ChartData<T>> = input.required<ChartData<T>>();
  readonly options: InputSignal<ChartOptions<T>> = input<ChartOptions<T>>({} as ChartOptions<T>);
  readonly plugins: InputSignal<Plugin<T>[]> = input<Plugin<T>[]>([]);

  readonly dataDisplayFn: InputSignal<DataDisplayFn> = input.required<DataDisplayFn>();

  private readonly chartOptions: Signal<ChartOptions<T>> = computed(() => this.applyDefaultOptions(this.options()));

  protected readonly legendItems: WritableSignal<LegendItem[]> = signal<LegendItem[]>([]);

  protected readonly tooltipConfig: WritableSignal<TooltipConfig | undefined> = signal<TooltipConfig | undefined>(
    undefined,
  );

  private chart: Chart<T> | undefined;

  get chartAspectRatio(): string {
    return `auto ${this.chart?.aspectRatio ?? 1} / 1`;
  }

  private readonly ngZone: NgZone = inject(NgZone);
  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);

  constructor() {
    effect(() => this.initChart(this.type(), this.data(), this.chartOptions(), this.plugins()));
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  refreshChart(): void {
    this.chart?.update();
  }

  private applyDefaultOptions(options: ChartOptions<T>): ChartOptions<T> {
    options = applyChartTypeBaseConfig(this.type(), options);
    options ??= {} as ChartOptions<T> & object;

    options.plugins ??= {} as PluginOptionsByType<T>;
    options.plugins.legend = { display: false };
    options.plugins.tooltip = {
      enabled: false,
      position: 'nearest',
      external: getExternalTooltipFunction((tooltipConfig: TooltipConfig) =>
        this.ngZone.run(() => this.tooltipConfig.set(tooltipConfig)),
      ),
    };
    options.plugins.colors = {
      enabled: false,
    };

    return options;
  }

  private initChart(type: T, data: ChartData<T>, options: ChartOptions<T>, plugins: Plugin<T>[]): void {
    this.ngZone.runOutsideAngular(() => {
      const chartPlugins: Plugin<T>[] = this.setupChartPlugins(plugins);

      this.chart?.destroy();

      untracked(() => {
        this.chart = new Chart(this.canvas.nativeElement, { type, data, options, plugins: chartPlugins });
      });
    });
  }

  private setupChartPlugins(customPlugins: Plugin<T>[]): Plugin<T>[] {
    const htmlLegendPlugin: Plugin<T> = getHtmlLegendPlugin((legendItems: LegendItem[]) =>
      this.ngZone.run(() => this.legendItems.set(legendItems)),
    );
    const colorsPlugin: Plugin<T> = getColorsPlugin(this.elementRef.nativeElement);

    return [...customPlugins, htmlLegendPlugin, colorsPlugin];
  }

  toggleDataVisibility(legendItemIndex: number): void {
    if (isDoughnutOrPieChart(this.type())) {
      // Pie and doughnut charts only have a single dataset and visibility is per item
      this.chart?.toggleDataVisibility(legendItemIndex);
    } else {
      const isDatasetVisible = this.chart?.isDatasetVisible(legendItemIndex);
      this.chart?.setDatasetVisibility(legendItemIndex, !isDatasetVisible);
    }

    this.chart?.update();
  }
}
