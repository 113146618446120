import {
  Chart,
  ChartConfiguration,
  ChartDataset,
  ChartType,
  TooltipItem,
  TooltipLabelStyle,
  TooltipModel,
} from 'chart.js';

import { TooltipConfig, TooltipValue } from '../models';
import { isDoughnutOrPieChart } from '../utils';

const TOOLTIP_TOP_OFFSET = 5;

export function getExternalTooltipFunction<T extends ChartType>(
  tooltipConfigChangeCallback: (tooltipConfig: TooltipConfig) => void,
): ({ chart, tooltip }: { chart: Chart; tooltip: TooltipModel<T> }) => void {
  return ({ chart, tooltip }: { chart: Chart; tooltip: TooltipModel<T> }) => {
    const opacity: number = tooltip.opacity;
    const colors: TooltipLabelStyle[] = tooltip.labelColors;

    const { offsetLeft: positionX, offsetTop: positionY, offsetWidth: chartWidth } = chart.canvas;

    const left: number = positionX + tooltip.caretX;
    const top: number = positionY + tooltip.caretY + TOOLTIP_TOP_OFFSET;
    const leftLimit = 0;
    const rightLimit: number = 2 * positionX + chartWidth;

    const chartType: T = (chart.config as ChartConfiguration<T>).type;

    tooltipConfigChangeCallback({
      visible: opacity > 0,
      title: getTooltipTitle(chartType, tooltip),
      values: getPreparedTooltipValues(chartType, tooltip),
      colors,
      left,
      top,
      leftLimit,
      rightLimit,
    });
  };
}

function getTooltipTitle<T extends ChartType>(chartType: T, tooltip: TooltipModel<T>): string {
  if (isDoughnutOrPieChart(chartType)) {
    return (tooltip.dataPoints[0].dataset as ChartDataset<T>).label ?? '';
  }

  return getTooltipInitialTitle(tooltip);
}

function getPreparedTooltipValues<T extends ChartType>(chartType: T, tooltip: TooltipModel<T>) {
  if (isDoughnutOrPieChart(chartType)) {
    return getTooltipValues(tooltip.dataPoints, getTooltipInitialTitle(tooltip));
  }

  return getTooltipValues(tooltip.dataPoints);
}

function getTooltipInitialTitle<T extends ChartType>(tooltip: TooltipModel<T>): string {
  return tooltip.title[0] || '';
}

function getTooltipValues<T extends ChartType>(tooltipItems: TooltipItem<T>[], titleOverride?: string): TooltipValue[] {
  return tooltipItems.map(({ dataset, raw }) => ({
    label: titleOverride ?? (dataset as ChartDataset<T>).label ?? '',
    value: raw as number,
  }));
}
