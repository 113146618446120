import { Chart } from 'chart.js';

import { GradientStep } from '../models';

export function getColorGradientFunction(
  gradientSteps: GradientStep[],
): (context: { chart: Chart }) => CanvasGradient | undefined {
  let width: number, height: number, gradient: CanvasGradient;

  return (context: { chart: Chart }): CanvasGradient | undefined => {
    const chart: Chart = context.chart;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
    const chartWidth = chartArea.width;
    const chartHeight = chartArea.height;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;

      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

      gradientSteps.forEach(({ step, color }) => {
        gradient.addColorStop(step, color);
      });
    }

    return gradient;
  };
}
