import { Chart, ChartType, LegendItem, Plugin } from 'chart.js';

export function getHtmlLegendPlugin<T extends ChartType>(
  legendItemsChangeCallback: (legendItems: LegendItem[]) => void,
): Plugin<T> {
  return {
    id: 'customLegend',
    afterUpdate: (chart: Chart<T>) => {
      const items: LegendItem[] | undefined = chart.options?.plugins?.legend?.labels?.generateLabels?.(chart as Chart);
      legendItemsChangeCallback(items ?? []);
    },
  };
}
