import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { Color } from 'chart.js';

import { DataDisplayFn } from '../../../models';

@Component({
  selector: 'mp-chart-list-item',
  standalone: true,
  templateUrl: './chart-list-item.component.html',
  styleUrl: './chart-list-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartListItemComponent {
  readonly label: InputSignal<string> = input.required<string>();

  readonly value: InputSignal<number | undefined> = input.required<number | undefined>();

  readonly background: InputSignal<Color | undefined> = input.required<Color | undefined>();

  readonly hidden: InputSignal<boolean> = input<boolean>(false);

  readonly dataDisplayFn: InputSignal<DataDisplayFn> = input.required<DataDisplayFn>();

  protected readonly formattedValue: Signal<string> = computed(() => this.getFormattedValue(this.value()));

  private getFormattedValue(value: number | undefined): string {
    if (!value) {
      return '';
    }

    return this.dataDisplayFn()(value);
  }
}
