import { ChartOptions, ChartType, PluginOptionsByType, TooltipOptions } from 'chart.js';

export function applyChartTypeBaseConfig<T extends ChartType>(
  chartType: T,
  chartOptions: ChartOptions<T>,
): ChartOptions<T> {
  chartOptions ??= {} as ChartOptions<T> & object;

  switch (chartType) {
    case 'doughnut':
      return { ...chartOptions, cutout: '85%' };
    case 'line':
      chartOptions.plugins ??= {} as PluginOptionsByType<T>;

      chartOptions.plugins.tooltip ??= {} as TooltipOptions<T>;
      chartOptions.plugins.tooltip.mode = 'index';
      chartOptions.plugins.tooltip.intersect = false;

      return {
        ...chartOptions,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
            hoverRadius: 4,
          },
        },
      };
    default:
      return chartOptions;
  }
}
