import { Chart, ChartDataset, ChartType, DoughnutController, Plugin } from 'chart.js';

import { GradientStep } from '../models';
import { getColorGradientFunction } from '../utils';

const CHART_PRIMARY_COLOR_CSS_VARIABLES: string[] = [
  '--chart-primary-color-0',
  '--chart-primary-color-1',
  '--chart-primary-color-2',
  '--chart-primary-color-3',
  '--chart-primary-color-4',
  '--chart-primary-color-5',
  '--chart-primary-color-6',
];

type ColorizerFn<T extends ChartType> = (dataset: ChartDataset<T>, datasetIndex: number) => void;

function getPrimaryColors(element: HTMLElement): string[] {
  const computedStyle = getComputedStyle(element);

  return CHART_PRIMARY_COLOR_CSS_VARIABLES.map((variable) => computedStyle.getPropertyValue(variable));
}

function getDatasetGradientSteps(color: string, datasetIndex: number): GradientStep[] {
  const GRADIENT_COLOR_MIX_RATIO = '24%';
  const GRADIENT_END_STEP = 0.9;

  const mainColor = `color-mix(in srgb, ${color} ${GRADIENT_COLOR_MIX_RATIO}, transparent)`;

  const [startColor, endColor] = datasetIndex % 2 === 0 ? ['transparent', mainColor] : [mainColor, 'transparent'];

  return [
    { step: 0, color: startColor },
    { step: GRADIENT_END_STEP, color: endColor },
  ];
}

function getColorizer<T extends ChartType>(chart: Chart<T>, colors: string[]): ColorizerFn<T> {
  let i = 0;
  return (dataset: ChartDataset<T>, datasetIndex: number): void => {
    const controller = chart.getDatasetMeta(datasetIndex).controller;
    if (controller instanceof DoughnutController) {
      dataset.backgroundColor = dataset.data.map(() => colors[i++]);
    } else if (controller) {
      dataset.borderColor = colors[i];
      dataset.backgroundColor = getColorGradientFunction(getDatasetGradientSteps(colors[i++], datasetIndex));
    }
  };
}

export function getColorsPlugin<T extends ChartType>(element: HTMLElement = document.documentElement): Plugin<T> {
  return {
    id: 'customColors',
    beforeLayout(chart: Chart<T>) {
      const {
        data: { datasets },
      } = chart.config;
      const colors: string[] = getPrimaryColors(element);

      const colorizer: ColorizerFn<T> = getColorizer(chart, colors);
      datasets.forEach(colorizer);
    },
  };
}
