import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { ChartType, Color, LegendItem } from 'chart.js';

import { DataDisplayFn } from '../../../models';
import { isDoughnutOrPieChart } from '../../../utils';
import { ChartListItemComponent } from '../chart-list-item/chart-list-item.component';

export type LegendItemsOrientation = 'horizontal' | 'vertical';

@Component({
  selector: 'mp-chart-legend',
  standalone: true,
  templateUrl: './chart-legend.component.html',
  styleUrl: './chart-legend.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ChartListItemComponent],
})
export class ChartLegendComponent<T extends ChartType> {
  readonly legendItems: InputSignal<LegendItem[]> = input.required<LegendItem[]>();

  readonly legendValues: InputSignal<number[]> = input.required<number[]>();

  readonly dataDisplayFn: InputSignal<DataDisplayFn> = input.required<DataDisplayFn>();

  readonly chartType: InputSignal<T> = input.required<T>();

  readonly legendItemsOrientation: InputSignal<LegendItemsOrientation> = input<LegendItemsOrientation>('vertical');

  readonly legendItemClick: OutputEmitterRef<number> = output<number>();

  emitLegendItemClick(legendItemIndex: number): void {
    this.legendItemClick.emit(legendItemIndex);
  }

  getItemMarkerColor({ fillStyle, strokeStyle }: LegendItem): Color | undefined {
    if (isDoughnutOrPieChart(this.chartType())) {
      return fillStyle;
    } else {
      return strokeStyle;
    }
  }
}
