<ul class="chart-legend" [class.chart-legend--horizontal]="legendItemsOrientation() === 'horizontal'">
  @for (legendItem of legendItems(); track $index) {
    <li
      class="chart-legend__item truncate"
      (click)="emitLegendItemClick(legendItem.index ?? legendItem.datasetIndex ?? 0)"
    >
      <mp-chart-list-item
        class="truncate"
        [label]="legendItem.text"
        [value]="legendValues()[$index]"
        [background]="getItemMarkerColor(legendItem)"
        [hidden]="!!legendItem.hidden"
        [dataDisplayFn]="dataDisplayFn()"
      />
    </li>
  }
</ul>
